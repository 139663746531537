import { IconProps } from 'app/constants/icons';
import useProfile from 'app/hooks/useProfile';
import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const FacebookIcon: React.FC<IconProps> = ({ outerFillColor = 'none', innerFillColor }) => {
  const { theme } = useProfile();
  const _innerFillColor = innerFillColor ?? theme.colors.primary1;

  return (
    <Svg width="100%" height="100%" viewBox="0 0 24 24" fill={outerFillColor}>
      <G opacity="0.5">
        <G clipPath="url(#clip0_3373_231658)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.64395 12.0949V23.6962C8.64395 23.8608 8.78705 24 8.97104 24H13.6116C13.7888 24 13.9387 23.8671 13.9387 23.6962V11.9051H17.3049C17.4753 11.9051 17.6116 11.7848 17.632 11.6266L17.9523 8.07595C17.9659 7.89873 17.816 7.74684 17.6252 7.74684H13.9387V5.22785C13.9387 4.63924 14.4566 4.15823 15.0903 4.15823H17.6797C17.8569 4.15823 18.0068 4.02532 18.0068 3.85443V0.303797C18.0068 0.139241 17.8637 0 17.6797 0H13.2981C10.7291 0 8.64395 1.93671 8.64395 4.32278V7.74684H6.32709C6.14991 7.74684 6 7.87975 6 8.05063V11.6013C6 11.7658 6.1431 11.9051 6.32709 11.9051H8.64395V12.0949Z"
            fill={_innerFillColor}
          />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_3373_231658">
          <Rect width="12" height="24" fill={_innerFillColor} transform="translate(6)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default FacebookIcon;
