import { SKILLHERO_THEME } from 'app/constants/theme';
import { IHomePageV3Fields } from 'app/contentful';
import CreateThemedStyle from 'app/design/create-themed-style';
import useProfile from 'app/hooks/useProfile';
import { Image, Text, View, useWindowDimensions } from 'react-native';
import { DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { MotiLink } from 'solito/moti';
import {
  AppStoreBadgeOutlineIcon,
  FacebookIcon,
  InstagramIcon,
  PlayStoreBadgeOutlineIcon,
  TiktokIcon,
  YoutubeIcon,
} from '../icons';

const BASE_CONTENT_WIDTH_SUBPAGE = 1032;
const BASE_CONTENT_WIDTH_BlOG = 1440;

type Props = {
  fields: IHomePageV3Fields;
  type?: 'default' | 'subpage' | 'blog';
};

const secondaryLinks = [
  {
    title: 'Acceptable Use',
    url: 'https://www.skillhero.works/acceptable-use',
  },
  {
    title: 'Privacy',
    url: 'https://www.skillhero.works/privacy-policy',
  },
  {
    title: 'Terms',
    url: 'https://www.skillhero.works/terms-of-service',
  },
  {
    title: 'Cookies',
    url: 'https://www.skillhero.works/cookie-policy',
  },
];

export const Footer: React.FC<Props> = ({ fields, type = 'default' }) => {
  const { theme } = useProfile();
  const styles = useStyles(theme);
  const { width } = useWindowDimensions();

  const SOCIAL_ICONS: any = {
    YouTube: <YoutubeIcon innerFillColor={SKILLHERO_THEME.colors.neutral3} />,
    Instagram: <InstagramIcon innerFillColor={SKILLHERO_THEME.colors.neutral3} />,
    TikTok: <TiktokIcon innerFillColor={SKILLHERO_THEME.colors.neutral3} />,
    Facebook: <FacebookIcon innerFillColor={SKILLHERO_THEME.colors.neutral3} />,
  };

  const stores = [
    {
      icon: <AppStoreBadgeOutlineIcon />,
      url: 'https://apps.apple.com/us/app/skillhero-app/id1667736907',
    },
    {
      icon: <PlayStoreBadgeOutlineIcon />,
      url: 'https://play.google.com/store/apps/details?id=works.skillhero.mobile',
    },
  ];

  return (
    <View style={styles.container}>
      <View
        style={[
          type === 'subpage'
            ? styles.footerSubpage
            : type === 'blog'
              ? styles.footerBlog
              : styles.footerDefault,
          type === 'subpage' &&
            width < BASE_CONTENT_WIDTH_SUBPAGE && {
              width: '100%',
            },
          type === 'blog' &&
            width < BASE_CONTENT_WIDTH_BlOG && {
              width: '100%',
            },
          styles.footer,
        ]}
      >
        <View style={styles.left}>
          <Image source={SKILLHERO_THEME.assets.logoSmallLight} style={styles.logoSmall} />
          <Image source={SKILLHERO_THEME.assets.logoWhite} style={styles.logoLarge} />
          <View style={styles.linksContainer}>
            <View style={styles.mainLinks}>
              {fields.homePageFooterNav?.map(
                (item /* Type for field is not properly defined */) => (
                  <MotiLink
                    key={item.sys.id}
                    href={(item.fields as any).linkUrl}
                    animate={({ hovered, pressed }) => {
                      'worklet';
                      const active = hovered || pressed;
                      return {
                        opacity: active ? 0.7 : 1,
                      };
                    }}
                    transition={{
                      type: 'timing',
                      duration: 100,
                    }}
                  >
                    <Text style={styles.mainLink}>{(item.fields as any).linkText}</Text>
                  </MotiLink>
                )
              )}
            </View>
            <View style={styles.secondaryContainer}>
              <Text style={styles.copyright}>&copy; 2024 SkillHero</Text>
              <View style={styles.secondaryLinks}>
                <View style={styles.divider} />
                {secondaryLinks.map((item) => (
                  <MotiLink
                    key={item.url}
                    href={item.url}
                    animate={({ hovered, pressed }) => {
                      'worklet';
                      const active = hovered || pressed;
                      return {
                        opacity: active ? 1 : 0.5,
                      };
                    }}
                    transition={{
                      type: 'timing',
                      duration: 100,
                    }}
                  >
                    <Text style={styles.secondaryLink}>{item.title}</Text>
                  </MotiLink>
                ))}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.right}>
          <View style={styles.storesContainer}>
            {stores.map((item) => (
              <MotiLink
                key={item.url}
                href={item.url}
                animate={({ hovered, pressed }) => {
                  'worklet';
                  const active = hovered || pressed;
                  return {
                    opacity: active ? 0.7 : 1,
                  };
                }}
                transition={{
                  type: 'timing',
                  duration: 100,
                }}
                style={styles.storeIcon}
              >
                {item.icon}
              </MotiLink>
            ))}
          </View>
          <View style={styles.dividerLarge} />
          <View style={styles.socialsContainer}>
            {fields.homePageFooterSocials?.map((item: any) => (
              <MotiLink
                key={item.sys.id}
                href={item.fields.linkUrl}
                animate={({ hovered, pressed }) => {
                  'worklet';
                  const active = hovered || pressed;
                  return {
                    opacity: active ? 0.7 : 1,
                  };
                }}
                transition={{
                  type: 'timing',
                  duration: 100,
                }}
                style={[
                  styles.socialIcon,
                  item.fields.linkText === 'Facebook' && { marginLeft: -4 },
                ]}
              >
                {SOCIAL_ICONS[item.fields.linkText] ?? null}
              </MotiLink>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

const useStyles = CreateThemedStyle(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.primary1,
      width: '100%',
    },
    footerDefault: {
      width: 1302,
    },
    footerBlog: {
      width: 1440,
    },
    footerSubpage: {
      width: BASE_CONTENT_WIDTH_SUBPAGE,
    },
    footer: {
      alignSelf: 'center',
      minHeight: 150,
      justifyContent: 'center',
      paddingHorizontal: 24,
      flexDirection: 'row',
    },
    left: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      gap: 24,
    },
    right: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 24,
    },
    logoSmall: {
      width: 37,
      height: 44,
    },
    logoLarge: {
      display: 'none',
    },
    linksContainer: {
      flex: 1,
      gap: 19,
    },
    mainLinks: {
      flexDirection: 'row',
      gap: 24,
      flexWrap: 'wrap',
    },
    mainLink: {
      fontFamily: 'Inter',
      fontWeight: '600',
      fontSize: 14,
      lineHeight: 20,
      color: theme.colors.neutral1,
    },
    secondaryLinks: {
      flexDirection: 'row',
      gap: 24,
    },
    secondaryLink: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: 20,
      color: theme.colors.neutral1,
    },
    secondaryContainer: {
      flexDirection: 'row',
      gap: 24,
    },
    copyright: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: 20,
      color: theme.colors.neutral1,
    },
    dividerLarge: {
      width: 1,
      height: 67,
      borderRightWidth: 1,
      borderColor: theme.colors.neutral1,
    },
    divider: {
      width: 1,
      height: 20,
      borderRightWidth: 1,
      borderColor: theme.colors.neutral1,
    },
    storeIcon: {
      width: 101.98,
      height: 29,
    },
    socialIcon: {
      width: 20,
      height: 20,
    },
    storesContainer: {
      gap: 8,
    },
    socialsContainer: {
      flexDirection: 'row',
      gap: 24,
    },
  }),
  (theme) => ({
    [maxSize(DEVICE_SIZES.LG)]: {
      footer: {
        width: '100%',
      },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      footer: {
        flexDirection: 'column-reverse',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 37,
        paddingTop: 32,
      },
      left: {
        flexDirection: 'column',
        width: '100%',
        gap: 16,
      },
      linksContainer: {
        width: '100%',
      },
      right: {
        width: '100%',
      },
      mainLinks: {
        flexDirection: 'column',
        gap: 16,
      },
      socialsContainer: {
        flex: 1,
        flexWrap: 'wrap',
      },
      divider: {
        display: 'none',
      },
      secondaryContainer: {
        flexDirection: 'column',
        gap: 4,
      },
      secondaryLinks: {
        gap: 8,
        flex: 1,
        flexWrap: 'wrap',
      },
      logoSmall: {
        display: 'none',
      },
      logoLarge: {
        marginTop: 32,
        width: 137,
        height: 16,
        alignSelf: 'flex-start',
        display: 'flex',
      },
      copyright: {
        color: theme.colors.neutral6,
      },
    },
  })
);
