import { IconProps } from 'app/constants/icons';
import useProfile from 'app/hooks/useProfile';
import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

export const YoutubeIcon: React.FC<IconProps> = ({ outerFillColor = 'none', innerFillColor }) => {
  const { theme } = useProfile();
  const _innerFillColor = innerFillColor ?? theme.colors.primary1;

  return (
    <Svg width="100%" height="100%" viewBox="0 0 24 24" fill={outerFillColor}>
      <G opacity="0.5">
        <Path
          d="M23.7 7.2C23.4 5.25 22.5 3.9 20.4 3.6C17.1 3 12 3 12 3C12 3 6.9 3 3.6 3.6C1.5 3.9 0.45 5.25 0.3 7.2C0 9.15 0 12 0 12C0 12 0 14.85 0.3 16.8C0.6 18.75 1.5 20.1 3.6 20.4C6.9 21 12 21 12 21C12 21 17.1 21 20.4 20.4C22.5 19.95 23.4 18.75 23.7 16.8C24 14.85 24 12 24 12C24 12 24 9.15 23.7 7.2ZM9 16.5V7.5L16.5 12L9 16.5Z"
          fill={_innerFillColor}
        />
      </G>
    </Svg>
  );
};

export default YoutubeIcon;
