import { ISeoSectionFields } from 'app/contentful';
import useProfile from 'app/hooks/useProfile';
import Head from 'next/head';
import { useMemo } from 'react';

interface Props {
  seoSection: {
    fields: ISeoSectionFields | undefined;
    canonical?: string;
  };
}

export const SeoHead = ({ seoSection }: Props) => {
  const { theme } = useProfile();

  const robotsAttribute = useMemo(() => {
    const index = seoSection?.fields?.hidePageFromSearchNoindex ? 'noindex' : 'index';
    const follow = seoSection?.fields?.excludeLinksFromSearchRankings ? 'nofollow' : 'follow';

    return `${index},${follow}`;
  }, [seoSection]);

  if (!seoSection) {
    return null;
  }

  return (
    <Head>
      <title>{seoSection.fields?.titleTag}</title>
      {seoSection.canonical ? <link rel="canonical" href={seoSection.canonical} /> : null}
      <link rel="icon" href={theme.urls.favicon} />
      <meta name="description" content={seoSection.fields?.metaDescription} />
      <meta name="robots" content={robotsAttribute} />
      <meta property="og:title" content={seoSection.fields?.titleTag} />
      <meta property="og:description" content={seoSection.fields?.metaDescription} />
      {seoSection.fields?.seoImage ? (
        <meta property="og:image" content={seoSection.fields?.seoImage.fields.file.url} />
      ) : null}
      {seoSection.fields?.seoImage ? (
        <meta property="og:image:alt" content={seoSection.fields?.seoImage.fields.title} />
      ) : null}
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seoSection.fields?.titleTag} />
      <meta name="twitter:description" content={seoSection.fields?.metaDescription} />
      {seoSection.fields?.seoImage ? (
        <meta name="twitter:image" content={seoSection.fields?.seoImage.fields.file.url} />
      ) : null}
      {seoSection.fields?.seoImage ? (
        <meta name="twitter:image:alt" content={seoSection.fields?.seoImage.fields.title} />
      ) : null}
      {seoSection.fields?.keywords ? (
        <meta name="keywords" content={seoSection.fields?.keywords.join(', ')} />
      ) : null}
    </Head>
  );
};
