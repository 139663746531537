import { IconProps } from 'app/constants/icons';
import useProfile from 'app/hooks/useProfile';
import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

export const TiktokIcon: React.FC<IconProps> = ({ outerFillColor = 'none', innerFillColor }) => {
  const { theme } = useProfile();
  const _innerFillColor = innerFillColor ?? theme.colors.primary1;

  return (
    <Svg width="100%" height="100%" viewBox="0 0 24 24" fill={outerFillColor}>
      <G opacity="0.5">
        <Path
          d="M10.194 8.937V13.059C9.67485 12.9221 9.13161 12.9034 8.6043 13.0044C8.077 13.1054 7.57907 13.3234 7.14723 13.6424C6.71538 13.9613 6.36063 14.3732 6.10911 14.8475C5.8576 15.3218 5.71574 15.8466 5.69398 16.383C5.66425 16.8449 5.73375 17.3078 5.89781 17.7405C6.06188 18.1733 6.3167 18.5659 6.64516 18.892C6.97361 19.2181 7.36807 19.4701 7.80202 19.631C8.23596 19.7919 8.69934 19.8581 9.16098 19.825C9.6273 19.8608 10.0958 19.7949 10.5342 19.6319C10.9725 19.4689 11.3703 19.2126 11.6999 18.8809C12.0296 18.5491 12.2833 18.1498 12.4435 17.7104C12.6038 17.271 12.6667 16.8021 12.628 16.336V0H16.705C17.398 4.315 19.556 5.316 22.445 5.778V9.913C20.4429 9.74881 18.5116 9.09645 16.82 8.013V16.18C16.82 19.88 14.63 24 9.19298 24C8.17295 23.9955 7.16406 23.7875 6.22547 23.3881C5.28687 22.9886 4.43746 22.4059 3.72701 21.6739C3.01657 20.942 2.45939 20.0755 2.08813 19.1254C1.71688 18.1754 1.53902 17.1607 1.56498 16.141C1.59729 15.0794 1.85424 14.0366 2.31889 13.0815C2.78355 12.1265 3.44535 11.2807 4.26069 10.6C5.07602 9.9193 6.02637 9.41915 7.04908 9.1325C8.07179 8.84586 9.14364 8.77923 10.194 8.937Z"
          fill={_innerFillColor}
        />
      </G>
    </Svg>
  );
};

export default TiktokIcon;
